import { styledComponent } from '../../styles/styled'
import { Box } from '../Box'

export const AlertContainer = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'type'
})<{ type: 'success' | 'error' | 'warning' | 'info' }>(({ theme, type }) => ({
  padding: '16px 12px',
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '8px',
  ...(type === 'success' && {
    backgroundColor: theme.palette.success.main
  }),
  ...(type === 'error' && {
    backgroundColor: theme.palette.error.main
  }),
  ...(type === 'warning' && {
    backgroundColor: theme.palette.warning.main
  }),
  ...(type === 'info' && {
    backgroundColor: theme.palette.neutral.main
  }),
  p: {
    fontWeight: 500,
    lineHeight: '20px',
    fontSize: '14px',
    color: theme.palette.neutral.light
  },
  svg: {
    fill: theme.palette.neutral.light
  }
}))

import { useBookSearchInfinite } from '@hermes/api-sdk'
import { ReactNode, useMemo } from 'react'
import { SearchContext } from './searchContext'
import { useDebounce } from './utils/useDebounce'
import { useInternalSearchProviderState } from './useInternalSearchProviderState'

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const searchState = useInternalSearchProviderState()
  const debouncedQuery = useDebounce(searchState.query, 300)

  const userIsTyping = searchState.query !== debouncedQuery

  const searchResults = useBookSearchInfinite({
    query: debouncedQuery,
    perPage: searchState.perPage,
    semanticEnabled: searchState.semanticEnabled,
    filterAttributes: searchState.filterAttributes,
    activeRefinements: searchState.activeRefinements,
    currentSortOption: searchState.currentSortOption
  })

  const value = useMemo(
    () => ({
      ...searchState,
      ...searchResults,
      isLoading: searchResults.isLoading || userIsTyping
    }),
    [searchState, searchResults, userIsTyping]
  )

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
}

import React from 'react'
import { AlertContainer } from './styles'
import { Button } from '../Button'
import CloseIcon from '../../icons/Close'

export interface AlertProps {
  type: 'success' | 'error' | 'warning' | 'info'
  children: React.ReactNode
  onClose: () => void
}
export const Alert = ({ type, onClose, children }: AlertProps) => (
  <AlertContainer type={type}>
    {children}
    {onClose && (
      <Button variant="ghost" onClick={onClose}>
        <CloseIcon width="24px" />
      </Button>
    )}
  </AlertContainer>
)

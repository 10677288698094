import { SearchIndexMapping, SortByAttribute } from '@hermes/api-sdk'
import useTranslateMessage from '@hooks/useTranslateMessage'
import getRegionFromLocale from './getRegionFromLocale'
import { extractLocaleFromPathname } from './localeHelpers'

export const getIndexFromLocale = (locale: string) => {
  const verifiedLocale = extractLocaleFromPathname(`/${locale}/`)
  const region = getRegionFromLocale(verifiedLocale)
  const index = SearchIndexMapping[region]
  if (!index) {
    throw new Error(`No search index found for locale: ${locale}`)
  }
  return index
}

export const getI18nLabelToSortOption = (attribute: SortByAttribute): string => {
  const { t } = useTranslateMessage()
  const i18nMapping = {
    [SortByAttribute.RATING]: t({ id: 'sort.rating.desc', defaultMessage: 'Highest rating' }),
    [SortByAttribute.POPULARITY]: t({ id: 'sort.popularity.desc', defaultMessage: 'Popularity' }),
    [SortByAttribute.RELEVANCE]: t({ id: 'sort.relevance', defaultMessage: 'Relevance' })
  }

  return i18nMapping[attribute]
}

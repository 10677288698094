'use client'

import React, { Suspense } from 'react'
import { IntlProvider } from 'react-intl'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'
import 'swiper/css/pagination'

import { SearchProvider } from '@hermes/search'
import { AnalyticsProvider } from '@lib/contexts/analyticsContext'
import FlashMessageProvider from '@lib/contexts/flashMessageContext'
import { PrevRouteProvider } from '@lib/contexts/previousRouterContext'
import { UserProvider } from '@lib/contexts/userContext'
import { Locale } from '@lib/utils/localeHelpers'
import { NuqsAdapter } from 'nuqs/adapters/next/app'
import { SearchToQueryProvider } from '../lib/contexts/searchToQueryContext'

interface ProvidersProps {
  children: React.ReactNode
  translations: Record<string, string>
  locale: Locale
}

export const Providers: React.FC<ProvidersProps> = ({ children, translations, locale }) => (
  <Suspense>
    <IntlProvider
      locale={locale}
      messages={translations}
      onError={(err) => {
        if (err.code === 'MISSING_TRANSLATION' || err.code === 'MISSING_DATA') {
          console.warn('Missing translation', err.message)
          return
        }
        throw err
      }}
    >
      <NuqsAdapter>
        <PrevRouteProvider>
          <FlashMessageProvider>
            <UserProvider locale={locale}>
              <AnalyticsProvider>
                <SearchProvider>
                  <SearchToQueryProvider>{children}</SearchToQueryProvider>
                </SearchProvider>
              </AnalyticsProvider>
            </UserProvider>
          </FlashMessageProvider>
        </PrevRouteProvider>
      </NuqsAdapter>
    </IntlProvider>
  </Suspense>
)

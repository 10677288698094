import { SortByAttribute, Refinement, SortingOption } from '@hermes/api-sdk'

import { useState } from 'react'

export const useInternalSearchProviderState = () => {
  const [query, setQuery] = useState('')
  const [perPage, setPerPage] = useState(10)
  const [filterAttributes, setFilterAttributes] = useState<string[]>([])
  const [semanticEnabled, setSemanticEnabled] = useState(false)
  const [activeRefinements, setActiveRefinements] = useState<Set<Refinement>>(new Set())
  const [currentSortOption, setCurrentSortOption] = useState<SortingOption>({ value: SortByAttribute.RELEVANCE })

  const resetQuery = () => setQuery('')
  const resetActiveRefinements = () => setActiveRefinements(new Set())
  const resetSorting = () => setCurrentSortOption({ value: SortByAttribute.RELEVANCE })

  return {
    query,
    setQuery,
    resetQuery,
    perPage,
    setPerPage,
    semanticEnabled,
    setSemanticEnabled,
    filterAttributes,
    setFilterAttributes,
    activeRefinements,
    setActiveRefinements,
    currentSortOption,
    setCurrentSortOption,
    resetActiveRefinements,
    resetSorting
  }
}
